import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColBoolean<T extends DataLike = DataLike> = {
  colType: "boolean";
  field: string;
  pin?: "left" | "right";
  headerName: string;
  editable?: boolean;
  value?: string[];
  filterable?: boolean;
  validate?: (row: any, input: any) => string;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  resolve?: (data: T) => string;
  valueFormatter?: (value: boolean) => string;
};

export const isColBoolean = (col: Col): col is ColBoolean =>
  col.colType === "boolean";
