import { IconButton, Snackbar } from "@mui/material";
import React from "react";
import { useState, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";

interface Props {
  errors: (string | undefined)[];
  onClose?: () => void;
}

export const ErrorLayer = (props: Props) => (
  <>
    {props.errors.map((error, num) => (
      <ErrorSnackBar
        error={error}
        key={num}
        num={num}
        onClose={props.onClose}
      />
    ))}
  </>
);

interface ErrorSnackBarProps {
  error: string | undefined;
  num: number;
  onClose?: () => void;
}

export const ErrorSnackBar = (props: ErrorSnackBarProps) => {
  const { onClose } = props;
  const [isOpen, setIsOpen] = useState<boolean>(props.error ? true : false);
  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ClickAwayListener onClickAway={onClose || handleClose}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        ContentProps={{
          sx: {
            background: "red",
            bottom: 100,
          },
        }}
        autoHideDuration={3000}
        message={props.error}
        open={isOpen}
        action={action}
        onClose={handleClose}
      />
    </ClickAwayListener>
  );
};
