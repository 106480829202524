import { Box, FormControl, MenuItem, Popper, Select } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useRef, useState } from "react";
import { ColEnum } from "../Col/ColEnum";
import { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";

export const EditCellEnumMultiple = (props: {
  params: CustomGridRenderEditCellParams<string[]>;
  list: ColEnum["dic"];
  externalStringState: {
    [key: string]: string;
  };
  externalBooleanState: {
    [key: string]: boolean;
  };
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
  multiple?: boolean;
  width?: number;
}) => {
  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();
  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [field, props.externalStringState],
  );
  const [val, setVal] = useState<string[] | string>(value ?? []);

  //pop error
  const ref = useRef<HTMLElement>(null);

  //handle error
  //update external state when some error occurred
  useEffect(() => {
    if (error !== "") {
      props.updateExternalBooleanState("isError_" + field)(true);
    } else {
      props.updateExternalBooleanState("isError_" + field)(false);
    }
  }, [error, field, props]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
        ref={ref}
      >
        <FormControl fullWidth sx={{ minWidth: 100 }}>
          <Select
            multiple={true}
            value={val}
            onChange={async (event) => {
              if ("value" in event.target) {
                const value = event.target.value;
                await apiRef.current.setEditCellValue({ id, field, value });
                setVal(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? value.split(",") : value,
                );
              }
            }}
            variant="standard"
            sx={{
              "& .MuiInput-input": {
                padding: 0,
              },
              border: "none",
            }}
          >
            {props.list.map((u, i) => (
              <MenuItem value={u.value} key={i}>
                {u.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Popper open={error !== ""} anchorEl={ref.current}>
        <Box
          sx={{
            fontSize: "11px",
            color: "red",
            fontWeight: "bold",
            backgroundColor: "#ffffff",
            width: props.width,
            maxWidth: "90%",
            borderRadius: 1,
            boxShadow: 2,
            margin: "4px auto",
            padding: "2px 4px",
          }}
        >
          {error}
        </Box>
      </Popper>
    </>
  );
};
