import { LocalizationProvider } from "@/components/contextProviders";
import BasePageLayout from "@/components/layouts/BasePageLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { TailorProvider } from "@tailor-platform/client";
import { LicenseInfo } from "@tailor/datagrid";
import GoogleTagManager from "@tailor/gtm";
import type { AppProps } from "next/app";
import { initializeDatadogRUM } from "@tailor-platform/monitoring";
import Head from "next/head";
import theme from "../styles/theme";
import { combineComponents } from "@/lib/helpers/component";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE ?? "");

// "if deployed and running on Vercel, initialize Datadog RUM"
if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  /**
   * sessionSampleRate: 20%
   * sessionReplaySampleRate: 100%
   * https://docs.datadoghq.com/ja/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
   * https://tailor.atlassian.net/browse/SDH-1619
   */
  initializeDatadogRUM({
    sessionSampleRate: 20,
    sessionReplaySampleRate: 100,
  });
}

function collectionOffsetLimitPagination() {
  return {
    keyArgs: [],
    merge(
      existing = { collection: [], totalCount: 0 },
      incoming = { collection: [], totalCount: 0 },
    ) {
      if (!existing) {
        return incoming;
      }
      return {
        ...incoming,
        collection: [...existing.collection, ...incoming.collection],
      };
    },
  };
}

export default function SDHApp({ Component, pageProps }: AppProps) {
  // hack to circumvert "Type error: 'Component' cannot be used as a JSX component."
  const AnyComponent = Component as any;
  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>SDH勤怠</title>
        <meta httpEquiv="Content-Language" content="ja" />
        <meta name="google" content="notranslate" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <TailorProvider
        fallbackLoginPath="/login"
        inMemoryCacheConfig={{
          typePolicies: {
            Query: {
              fields: {
                workRecords: collectionOffsetLimitPagination(),
              },
            },
          },
        }}
      >
        <CssBaseline />
        <GoogleTagManager googleTagManagerId={process.env.NEXT_PUBLIC_GTM_ID} />
        <BasePageLayout>
          {combineComponents(<AnyComponent {...pageProps} />, {
            component: LocalizationProvider,
          })}
        </BasePageLayout>
      </TailorProvider>
    </ThemeProvider>
  );
}
