import clsx from "clsx";
import { GridColType } from "@mui/x-data-grid-pro";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import { CellString } from "../Cell/CellString";
import { ColTime } from "../Col/ColTime";

type ColTimeDefResult = {
  field: string;
  headerName: string;
  type: GridColType;
  filterable?: boolean;
  editable?: boolean;
  sortable?: boolean;
  width?: number;
  renderCell: (params: CustomGridRenderCellParams<string>) => JSX.Element;
  cellClassName: () => string;
};

export const ColTimeDef = (colProp: {
  col: ColTime;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}): ColTimeDefResult => {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "string",
    filterable: colProp.col.filterable,
    editable: colProp.col.editable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    renderCell: (params: CustomGridRenderCellParams<string>) => (
      <CellString
        params={params}
        valueFormatter={(value: string) => {
          if (value == null) {
            return "";
          }
          const date = new Date(value);
          return (
            date.getHours().toString().padStart(2, "0") +
            ":" +
            date.getMinutes().toString().padStart(2, "0")
          );
        }}
      />
    ),
    //TODO: edit cell
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
  };
};
