import { GridColType } from "@mui/x-data-grid-pro";
import { ColHidden } from "../Col/ColHidden";

type ColHiddenDefResult = {
  field: string;
  headerName: string;
  type: GridColType;
  filterable?: boolean;
  editable?: boolean;
  sortable?: boolean;
  hideable: boolean;
  hide: boolean;
};

export const ColHiddenDef = (colProps: {
  col: ColHidden;
}): ColHiddenDefResult => {
  return {
    field: colProps.col.field,
    headerName: "none",
    type: "string",
    sortable: false,
    editable: true,
    hide: true,
    filterable: false,
    hideable: false,
  };
};
