import {
  GridCellParams,
  GridColType,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import { ColIntTime } from "../Col/ColIntTime";
import { CellIntTime } from "../Cell/CellIntTime";

type ColIntTimeDefResult = {
  field: string;
  headerName: string;
  type: GridColType;
  filterable?: boolean;
  editable?: boolean;
  sortable?: boolean;
  width?: number;
  renderCell: (params: GridRenderCellParams) => JSX.Element;
  cellClassName: (params: GridCellParams) => string;
};

export const ColIntTimeDef = (colProp: {
  col: ColIntTime;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}): ColIntTimeDefResult => {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "number",
    filterable: colProp.col.filterable,
    editable: colProp.col.editable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    renderCell: (params: GridRenderCellParams) => (
      <CellIntTime params={params} />
    ),
    //TODO: edit cell
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
  };
};
