import { getGridStringOperators, GridColType } from "@mui/x-data-grid-pro";
import clsx from "clsx";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { CellString } from "../Cell/CellString";
import { ColString } from "../Col/ColString";
import { EditCellString } from "../EditCell/EditCellString";
import { EditCellStringAutoComplete } from "../EditCell/EditCellStringAutoComplete";

type ColStringDefResult = {
  field: string;
  headerName: string;
  type: GridColType;
  filterable?: boolean;
  hide?: boolean;
  editable?: boolean;
  sortable?: boolean;
  width?: number;
  noOptionsText?: string;
  filterOperators: any[];
  renderCell: (params: CustomGridRenderCellParams<string>) => JSX.Element;
  renderEditCell: <T extends boolean>(
    params: T extends boolean
      ? CustomGridRenderCellParams<string>
      : CustomGridRenderEditCellParams<string>,
  ) => JSX.Element;
  cellClassName: () => string;
};

export const ColStringDef = (colProp: {
  col: ColString;
  externalRowBooleanState: (rowId: number | string) => {
    [key: string]: boolean;
  };
  updateExternalRowBooleanState: (
    rowId: number | string,
  ) => (key: string) => (value: boolean) => void;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
  updateExternalRowStringState: (
    rowId: number | string,
  ) => (key: string) => (value: string) => void;
}): ColStringDefResult => {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    hide: colProp.col.hide,
    type: "string" as GridColType,
    editable: colProp.col.editable || colProp.col.indirectEditable,
    filterable: colProp.col.filterable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    noOptionsText: colProp.col.noOptionsText,
    filterOperators: getGridStringOperators().filter(
      (o) => o.value === "equals" || o.value === "contains",
    ),
    renderCell: (params: CustomGridRenderCellParams<string>) => (
      <CellString valueFormatter={(val: string) => val} params={params} />
    ),
    renderEditCell: colProp.col.indirectEditable
      ? (params: CustomGridRenderCellParams<string>) => (
          <CellString valueFormatter={(val: string) => val} params={params} />
        )
      : colProp.col.autoComplete
      ? (params: CustomGridRenderEditCellParams<string>) => (
          <EditCellStringAutoComplete
            params={params}
            validate={colProp.col.validate}
            validateAsync={colProp.col.validateAsync}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id,
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            updateExternalStringState={colProp.updateExternalRowStringState(
              params.id,
            )}
            externalStringState={colProp.externalRowStringState(params.id)}
            autoComplete={colProp.col.autoComplete}
            autoCompleteOptionList={colProp.col.autoCompleteOptionList}
            width={colProp.col.width}
            noOptionsText={colProp.col.noOptionsText}
          />
        )
      : (params: CustomGridRenderEditCellParams<string>) => (
          <EditCellString
            params={params}
            validate={colProp.col.validate}
            validateAsync={colProp.col.validateAsync}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id,
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            updateExternalStringState={colProp.updateExternalRowStringState(
              params.id,
            )}
            width={colProp.col.width}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        ),
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
  };
};
