import { Box } from "@mui/material";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";

export const CellNumber = (props: {
  params: CustomGridRenderCellParams<number>;
  unit?: string;
}) => {
  const { value } = props.params;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {value?.toString() + (props.unit ?? "")}
    </Box>
  );
};
