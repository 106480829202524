import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColString<T extends DataLike = DataLike> = {
  colType: "string";
  field: string;
  headerName: string;
  validate?: (row: any, input: any) => string;
  validateAsync?: (row: any, input: any) => Promise<string>;
  autoComplete?: (val: string) => void;
  autoCompleteOptionList?: { [fieldName: string]: any }[];
  editable?: boolean;
  indirectEditable?: boolean;
  filterable?: boolean;
  nullable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  pin?: "left" | "right";
  noOptionsText?: string;
  resolve?: (data: T) => string;
};

export const isColString = (col: Col): col is ColString =>
  col.colType === "string";
