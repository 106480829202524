import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColHidden<T extends DataLike = DataLike> = {
  colType: "hidden";
  field: string;
  pin?: "left" | "right";
  type:
    | "string"
    | "number"
    | "date"
    | "dateTime"
    | "boolean"
    | "singleSelect"
    | "actions";
  isKey?: boolean;
  resolve?: (data: T) => string;
};

export const isColHidden = (col: Col): col is ColHidden =>
  col.colType === "hidden";
