import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColDate<T extends DataLike = DataLike> = {
  colType: "date";
  field: string;
  pin?: "left" | "right";
  headerName: string;
  validate?: (row: any, input: any) => string;
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  tz?: string;
  resolve?: (data: T) => string;
};

export const isColDate = (col: Col): col is ColDate => col.colType === "date";

// Definition
// # format of state
//    -  "yyyy-mm-ddThh:mm:ssZ" (String; same with GraphQL datetime types)
// # format of displayed vlaue
//    -  "yyyy-mm-dd"
// # edit cell type
//    - CellString.tsx
// # render cell type
//    - CellString.tsx
